import React from 'react';

const Home = () => {
  return (
    <div>
     <h1>Anasayfaya hoşgeldin</h1>
    </div>
  );
};

export default Home;
