import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import '../style/UserDetail.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaWhatsapp, FaTwitter, FaYoutube, FaPhone, FaEnvelope, FaCopy } from 'react-icons/fa';
import { MdLocationOn, MdWeb } from 'react-icons/md';

const UserDetail = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);

  const defaultImageURL = 'https://member.kartlas.com/profile.png';

  useEffect(() => {
    fetch(`https://api.kartlas.com/api/User/UserAllInformationWeb?a=${id}`)
      .then(response => response.json())
      .then(data => {
        setUserData(data[0]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setLoading(false);
      });
  }, [id]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openQrModal = () => setIsQrModalOpen(true);
  const closeQrModal = () => setIsQrModalOpen(false);

  const parseField = (field) => {
    if (!field) return [];
    return field.split(';').map(part => part.split('\\').pop().trim());
  };
  
  const getSocialUsername = (url) => {
    if (!url) return '';
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  };

  const fetchImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl, {
        method: 'GET',
        headers: { "Cache-Control": 'no-cache' },
    });
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
};

  
  const parseSocialMedia = (data) => {
    if (!data) return [];
    return data.split(';').map(item => {
      const parts = item.split('\\');
      if (parts.length < 2) return null;
      return {
        name: parts[0].trim(),
        url: parts[1].trim(),
      };
    }).filter(Boolean);
  };

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const isAndroid = () => {
    return /Android/.test(navigator.userAgent);
  };

  const generateVCard = async () => {
    if (!userData) return '';

    const isim = parseField(userData.Isim);
    const soyisim = parseField(userData.Soyisim);
    const email = parseField(userData.EMAIL);
    const phone = parseField(userData.TELEFON);
    const address = parseField(userData.ADRES);
    const website = parseField(userData.WEBSITE);
    const company = parseField(userData.SirketAdi);
    const facebook = parseSocialMedia(userData.FACEBOOK);
    const instagram = parseSocialMedia(userData.INSTAGRAM);
    const linkedin = parseSocialMedia(userData.LINKEDIN);
    const tiktok = parseSocialMedia(userData.TIKTOK);
    const whatsapp = parseField(userData.WHATSAPP);
    const x = parseSocialMedia(userData.X);
    const youtube = parseSocialMedia(userData.YOUTUBE);

    let imageBase64 = '';
    try {
        imageBase64 = await fetchImageAsBase64(userData.Resim || defaultImageURL);
    } catch (error) {
        console.error('Error fetching image:', error);
        imageBase64 = await fetchImageAsBase64(defaultImageURL);
    }
    const photoType = imageBase64.split(';')[0].split(':')[1];

    let vCardData = `BEGIN:VCARD\n`;
    vCardData += `VERSION:3.0\n`;
    vCardData += `FN:${isim} ${soyisim}\n`;
    vCardData += `N:${soyisim};${isim};;;\n`;
    vCardData += `ORG:${company}\n`;
    vCardData += `EMAIL:${email}\n`;
    vCardData += `TEL:${phone}\n`;
    vCardData += `ADR:${address}\n`;
    vCardData += `URL:${website}\n`;

    // Platform-specific vCard generation
    if (isIOS()) {
        if (facebook.length > 0) {
            facebook.forEach(item => {
                const username = getSocialUsername(item.url);
                vCardData += `X-SOCIALPROFILE;TYPE=Facebook:${username}:${item.url}\n`;
            });
        }
        if (instagram.length > 0) {
            instagram.forEach(item => {
                const username = getSocialUsername(item.url);
                vCardData += `X-SOCIALPROFILE;TYPE=Instagram:${username}:${item.url}\n`;
            });
        }
        if (linkedin.length > 0) {
            linkedin.forEach(item => {
                const username = getSocialUsername(item.url);
                vCardData += `X-SOCIALPROFILE;TYPE=Linkedin:${item.url}\n`;
            });
        }
        if (tiktok.length > 0) {
            tiktok.forEach(item => {
                const username = getSocialUsername(item.url);
                vCardData += `X-SOCIALPROFILE;TYPE=Tiktok:${username}:${item.url}\n`;
            });
        }
        if (x.length > 0) {
            x.forEach(item => {
                const username = getSocialUsername(item.url);
                vCardData += `X-SOCIALPROFILE;TYPE=X:${username}:${item.url}\n`;
            });
        }
        if (youtube.length > 0) {
            youtube.forEach(item => {
                const username = getSocialUsername(item.url);
                vCardData += `X-SOCIALPROFILE;TYPE=Youtube:${username}:${item.url}\n`;
            });
        }
    } else if (isAndroid()) {
        if (facebook.length > 0) {
            facebook.forEach(item => {
                vCardData += `URL:${item.url}\n`;
            });
        }
        if (instagram.length > 0) {
            instagram.forEach(item => {
                vCardData += `URL:${item.url}\n`;
            });
        }
        if (linkedin.length > 0) {
            linkedin.forEach(item => {
                vCardData += `URL:${item.url}\n`;
            });
        }
        if (tiktok.length > 0) {
            tiktok.forEach(item => {
                vCardData += `URL:${item.url}\n`;
            });
        }
        if (x.length > 0) {
            x.forEach(item => {
                vCardData += `URL:${item.url}\n`;
            });
        }
        if (youtube.length > 0) {
            youtube.forEach(item => {
                vCardData += `URL:${item.url}\n`;
            });
        }
        if (whatsapp.length > 0) {
            whatsapp.forEach(item => {
                vCardData += `TEL;TYPE=VOICE,WHATSAPP:${item}\n`;
            });
        }
    }

    vCardData += `PHOTO;ENCODING=b;TYPE=${photoType}:${imageBase64.split(',')[1]}\n`;
    vCardData += `END:VCARD`;

    return vCardData;
};



  
  const downloadVCard = async () => {
    const vCardData = await generateVCard();
    const blob = new Blob([vCardData], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${userData.Isim}_${userData.Soyisim}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('IBAN kopyalandı: ' + text);
    }).catch((error) => {
      console.error('IBAN kopyalanamadı: ', error);
    });
  };

  if (loading) {
    return <div className="loading">Yükleniyor...</div>;
  }

  if (!userData) {
    return <div className="no-data">Kullanıcı Bulunamadı</div>;
  }

  const qrValue = `https://member.kartlas.com/${id}`;

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handleLocationClick = () => {
    window.open(`https://maps.google.com/maps?q=${parseField(userData.ADRES)}`);
  };

  const handleWebsiteClick = (websiteURL) => {
    window.open(websiteURL, '_blank');
  };

  const handleSocialMediaClick = (socialMediaURL) => {
    window.open(socialMediaURL, '_blank');
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `${userData.Isim} ${userData.Soyisim}`,
        text: `Profiline göz at ${userData.Isim} ${userData.Soyisim}`,
        url: `https://member.kartlas.com/${id}`,
      })
      .then(() => console.log('Başarıyla Paylaşıldı'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API is not supported in this browser.');
    }
  };

  return (
    <div>
      <div className="card">
        <div className="qr-code-container" onClick={openQrModal}>
          <QRCode value={qrValue} size={50} className="qr-code" />
        </div>
        <div className="image-container" onClick={openModal}>
          <img className="img" src={userData.Resim || defaultImageURL} />
        </div>
        <div className="details">
          <h1>{`${userData.Isim} ${userData.Soyisim}`}</h1>
          <p>{userData.SirketAdi}</p>
          <p>{userData.IsUnvani}</p>
        </div>
        <div className="button">
          <button onClick={downloadVCard} className="vcard-button">Rehbere Kaydet</button>
          <button onClick={handleShare} className="share-button">Paylaş</button>
        </div>
        <div className="contact-info">
          {userData.TELEFON && parseField(userData.TELEFON).map((item, index) => (
            <p key={index} onClick={() => handlePhoneClick(item)}>
              <FaPhone className="contact-icon" /> {item}
            </p>
          ))}
          {userData.EMAIL && parseField(userData.EMAIL).map((item, index) => (
            <p key={index} onClick={() => handleEmailClick(item)}>
              <FaEnvelope className="contact-icon" /> {item}
            </p>
          ))}
          {userData.ADRES && parseField(userData.ADRES).map((item, index) => (
            <p key={index} onClick={() => handleLocationClick(item)}>
              <MdLocationOn className="contact-icon" /> {item}
            </p>
          ))}
          {userData.WEBSITE && parseField(userData.WEBSITE).map((item, index) => (
            <p key={index} onClick={() => handleWebsiteClick(item)}>
              <MdWeb className="contact-icon" /> {item}
            </p>
          ))}
          {userData.BANKA && parseField(userData.BANKA).map((item, index) => (
            <p key={index} onClick={() => copyToClipboard(item)}>
              <FaCopy className="contact-icon" /> {item}
            </p>
          ))}
        </div>

        {(userData.FACEBOOK || userData.INSTAGRAM || userData.LINKEDIN || userData.TIKTOK || userData.WHATSAPP || userData.X || userData.YOUTUBE) && (
          <div>
            <p className="social-title">Sosyal Medya</p>
            <div className="social-media">
              {userData.FACEBOOK && parseField(userData.FACEBOOK).map((link, index) => (
                <p key={index} onClick={() => handleSocialMediaClick(link)}>
                  <FaFacebook className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
              {userData.INSTAGRAM && parseField(userData.INSTAGRAM).map((link, index) => (
                <p key={index} onClick={() => handleSocialMediaClick(link)}>
                  <FaInstagram className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
              {userData.LINKEDIN && parseField(userData.LINKEDIN).map((link, index) => (
                <p key={index} onClick={() => handleSocialMediaClick(link)}>
                  <FaLinkedin className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
              {userData.TIKTOK && parseField(userData.TIKTOK).map((link, index) => (
                <p key={index} onClick={() => handleSocialMediaClick(link)}>
                  <FaTiktok className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
              {userData.WHATSAPP && parseField(userData.WHATSAPP).map((link, index) => (
                <p key={index} onClick={() => window.location.href = `https://wa.me/${link}`}>
                  <FaWhatsapp className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
              {userData.X && parseField(userData.X).map((link, index) => (
                <p key={index} onClick={() => handleSocialMediaClick(link)}>
                  <FaTwitter className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
              {userData.YOUTUBE && parseField(userData.YOUTUBE).map((link, index) => (
                <p key={index} onClick={() => handleSocialMediaClick(link)}>
                  <FaYoutube className="social-icon" /> {getSocialUsername(link)}
                </p>
              ))}
            </div>
          </div>
        )}
        <div className="kartlas-info">
          <a href="https://kartlas.com">
            <img src="https://member.kartlas.com/logo.svg" alt="Kartlas" />
          </a>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <img src={userData.Resim} alt={`${userData.Isim} ${userData.Soyisim}`} className="modal-image" />
          </div>
        </div>
      )}

      {isQrModalOpen && (
        <div className="modal" onClick={closeQrModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close-button" onClick={closeQrModal}>&times;</span>
            <QRCode value={qrValue} size={256} className="modal-qr-code" />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetail;
