import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserDetail from './pages/UserDetail';
import Home from './pages/Home'; // Eğer bir ana sayfa bileşeni eklemek isterseniz

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<UserDetail />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
